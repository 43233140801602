<template>
  <div class="news-show-container">
    <div class="news_bg">
      <div class="margin width1480 hidden">
        <div class="news_s_tit">
          <a href="">首页</a> > <a href="">新闻中心</a> >
          打响“新工厂+直播基地”双响炮，挚达520惊喜到达！
        </div>
        <ul class="news_show">
          <li class="t">打响“新工厂+直播基地”双响炮，挚达520惊喜到达！</li>
          <li class="n">
            <i>挚达科技</i><i>2022-05-20</i
            ><span
              ><img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/k.png"
              />
              3000</span
            >
          </li>
          <li class="c">
            <p>
              疫情期间，挚达科技全体员工戮力同心，持续发力挚达科技各项业务建设，不断加强挚达科技发展动能。2022年5月20日，挚达科技迎来了生产和营销端阶段性成果的集中落地。挚达科技管理团队及相关部门员工通过线上与线下联动的方式，共同见证了这两个高光时刻。
            </p>
            <p><br /></p>
            <p style="text-align: center">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/news_s1.jpg"
              />
            </p>
            <p style="color: #0091d8; text-align: center">
              第二生产基地一期工程成功试产
            </p>
            <p><br /></p>
            <p>
              经过6个月的建设及布局，在挚达上海总部及安庆挚达的共同努力与奋斗下，位于安徽省安庆市的挚达第二生产基地一期工程于5月20日上午顺利试产。
            </p>
            <p>
              本次试产检验了各个车间设备的运行情况，进行了正式生产的预演，同时也为产线实操员工提供了技能培训，为日后的量产打下了坚实的基础。
            </p>
            <p><br /></p>
            <p style="text-align: center">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/news_s2.jpg"
              />
            </p>
            <p><br /></p>
            <p>
              在项目建设过程中，挚达坚持高起点、高标准方案，采用全球化的设计标准、成熟的工艺技术以及汽车级的品质控制，为高质量的产品输出提供了强力的保障。同时，生产线全线采用数字化MES系统，是挚达第一个全面实现数字化生产的工厂。
            </p>
            <p><br /></p>
            <p>
              在碳达峰、碳中和背景下，我国新能源汽车产业发展速度持续加速，作为新能源汽车配套基础设施的充电桩也进入“增长快车道”，被列为国家新基建七大领域之一。随着市场的需求与认可度不断提升，挚达智能充电桩产品出货呈爆发式增长态势，预计2022年将比2020年出货量增长超过10倍。
            </p>
            <p><br /></p>
            <p style="text-align: center">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/news_s3.jpg"
              />
            </p>
            <p><br /></p>
            <p>
              日后，挚达将把“坚持安全生产、清洁生产、高质量生产、稳定生产”的理念转化为切实的生产力，为市场输出更多高质量的充电桩产品，推动电动汽车及绿色能源进入家庭。
            </p>
            <p><br /></p>
            <p style="text-align: center">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/news_s4.jpg"
              />
            </p>
            <p style="color: #0091d8; text-align: center">
              挚达·新能源生活馆顺利启用
            </p>
            <p><br /></p>
            <p>
              在挚达上海总部与三明挚达的协同努力下，5月20日上午，位于福建省三明市的挚达·新能源生活馆正式宣布落成。
            </p>
            <p>
              挚达·新能源生活馆主打新能源体验主题，不仅作为挚达新能源汽车充电桩产品的集中展示场馆，也将为新能源汽车的展览展示与互动，以及户用光伏的体验推广提供载体。
            </p>
            <p><br /></p>
            <p style="text-align: center">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/news_s5.jpg"
              />
            </p>
            <p><br /></p>
            <p>
              除线下展示与体验外，生活馆也将联动线上资源，作为挚达的首个直播基地，通过抖音等渠道触达家庭用户，为更多家庭提供专业的新能源服务。
            </p>
            <p>
              挚达·新能源生活馆正是集成了“「新能源汽车下乡+挚达充电桩到家+光伏到户」的线上+线下联动的沉浸式生活馆”，这已然成为中国新能源行业中一道靓丽的风景线。三明挚达·新能源生活馆将作为试点首先运营，希望通过模式的创新，为日后的大规模推广积累经验、奠定基础。
            </p>
            <p><br /></p>
            <p style="text-align: center">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/news_s6.jpg"
              />
            </p>
          </li>
          <li class="f">
            <dl><a href="">上一篇：挚·爱318 | 自由之路，充电随心！</a></dl>
            <dl>
              <a href=""
                >下一篇：200万台！挚达科技参与安徽全省重大项目“云签约”活动</a
              >
            </dl>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
export default {
  name: 'news_show',
  components: {},
  setup() {
    const {proxy} = getCurrentInstance()
    onMounted(() => {
      document.title = '新闻详情'
      
      // 滚动到页面最上方
      window.scrollTo(0, 0);
    })
    return {}
  },
}
</script>

<style scoped></style>
